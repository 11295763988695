@charset "UTF-8";
.light--blue--color-shade--6 {
  background: #0065b7;
}

.brand--bg--color {
  background: #003399;
}

.brand--text--color {
  color: #003399 !important;
}

.light--blue--color {
  background: #dde2ec;
}

.in--active--blue--color {
  background: #7d98c9;
}

.black--color-text {
  color: #141b2f;
}

.white--color-text {
  color: #fafafa !important;
}

.blue--color--text {
  color: #003399;
}

.grey--color--text {
  color: #5c5c5c;
}

.green--color--text {
  color: #269f00;
}

.red--color--text {
  color: #c43a4b;
}

.black--color--00--text {
  color: #000000;
}

.digit--blue--color {
  color: #05c098;
}

.digit--red--color {
  color: #f5555f;
}

.bg--digit--blue--color {
  background: #05c098;
}

.bg--digit--red--color {
  background: #f5555f;
}

.white--background--color {
  background-color: #fafafa;
}

.black--background--color {
  background-color: #141b2f;
}

.black--color--00--bg {
  background: #000000 !important;
}

.blue--background--color {
  background-color: #003399;
}

.light--grey--color-shade--3 {
  background-color: #f0f0f0;
}

.light--blue--bg-color-shade--1 {
  background-color: #7d96c9;
}

.light--blue--bg-color-shade--2 {
  background-color: #c8d2e7;
}

.light--bg--color {
  background-image: linear-gradient(#86adfd, #486aae) !important;
}

.dark--blue--bg-color-shade--1 {
  background-color: #131b2e;
}

.bg-green-dark {
  background: #269f00;
}

.bg-red-dark {
  background: #C43A4B;
}

.brand--color--border {
  border: 1px solid #003399;
}

.brand--border--blue {
  border: 1px solid #003399;
}

.red--color--border {
  border: 1px solid #c43a4b;
}

.green--color--border {
  border: 1px solid #269f00;
}

.black--color--border {
  border: 1px solid #141b2f;
}

.white--color--border {
  border: 1px solid #fafafa;
}

.n-primary-black-bg {
  background: #001316;
}

.n-primary-cyan-bg {
  background: #75ffff;
}

.n-primary-orange-bg {
  background: #d16014;
}

.n-primary-white-bg {
  background: #fffaf7;
}

.n-primary-dark-orge-bg {
  background: #9e6240;
}

.n-primary-purple-bg {
  background: #776871;
}

.n-primary-green-bg {
  background: #339581;
}

.active--cyan--color {
  background: #75ffff;
  border: 1px solid #75ffff;
}

.in--active--cyan--color {
  background: #2d898b;
  border: 1px solid #2d898b;
}

.n-primary-black-txt {
  color: #001316;
}

.n-primary-black-txt-imp {
  color: #001316 !important;
}

.n-primary-cyan-txt {
  color: #75ffff;
}

.n-primary-orange-txt {
  color: #d16014;
}

.n-primary-white-txt {
  color: #fffaf7;
}

.n-grey-color-100-txt {
  color: #7f8686;
}

.n-primary-dark-orge-txt {
  color: #9e6240;
}

.n-primary-purple-txt {
  color: #776871;
}

.n-primary-green-txt {
  color: #339581;
}

.n-primary-cyan-border {
  border: 1px solid #75ffff;
}

.n-primary-white-border {
  border: 1px solid #fffaf7;
}

.neuePowerTrial-Regular {
  font-family: "neuePowerTrial-Regular";
}

.neuePowerTrial-Medium {
  font-family: "neuePowerTrial-Medium";
}

.canela-LightItalic {
  font-family: "canela-LightItalic";
}

.neueMontreal-Thin {
  font-family: "neueMontreal-Thin";
}

.neueMontreal-Book {
  font-family: "neueMontreal-Book";
}

.neueMontreal-Regular {
  font-family: "neueMontreal-Regular";
}

.roboto-sans-serif {
  font-family: "Roboto", sans-serif;
}

.pp-neue-montreal {
  font-family: "PP Neue Montreal", sans-serif;
}

.neue-power-sans-serif {
  font-family: "Neue Power", sans-serif;
}

.neue-bold {
  font-family: "Neue-bold";
}

.poppins-sans-serif {
  font-family: "Poppins", sans-serif;
}

.new_buttons_theme {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.875rem;
  border: 0px;
  cursor: pointer;
  height: 100%;
}

.new_buttons_theme_a {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.875rem;
  border: 1px solid #75ffff;
  cursor: pointer;
}

.font-12 {
  font-size: 0.75rem !important;
  line-height: 0.875rem !important;
}

.font-14 {
  font-size: 0.875rem;
  line-height: 1rem;
}

.font-wt-300 {
  font-weight: 300;
}

.font-wt-400 {
  font-weight: 400;
}

.font-wt-500 {
  font-weight: 500;
}

.font-wt-600 {
  font-weight: 600;
}

.font-wt-700 {
  font-weight: 700;
}

.banner-main-heading {
  font-family: "neuePowerTrial-Regular";
  font-size: 6.25rem;
  font-weight: 400;
  line-height: 7.5rem;
  text-align: center;
  color: #fff;
}
.banner-main-heading span {
  font-family: "canela-LightItalic";
  font-weight: 300;
}

.common-heading {
  font-family: "neuePowerTrial-Regular";
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 3.75rem;
}
.common-heading span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 300;
}

.big-heading {
  font-family: "neuePowerTrial-Medium";
  font-size: 4rem;
  font-weight: 400;
  line-height: 4.75rem;
  text-align: left;
}

.font-70 {
  font-family: "neuePowerTrial-Regular";
  font-size: 4.375rem;
  font-weight: 500;
  line-height: 5.25rem;
  text-align: center;
}

.buttons__theme {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.9625vw;
  font-weight: 500;
}

.heading--text {
  font-size: 3.125rem;
  font-weight: 600;
  line-height: 4.688rem;
}

.sub-heading--text {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
}
.sub-heading--text span {
  color: #003399;
}

.sub-heading--text-16 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-weight: 400;
}

body {
  background-color: #001316;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

img {
  width: 100%;
}

.homepage-main-banner-section {
  position: relative;
  top: 10px;
  width: 100%;
  float: left;
  background: #001316;
}
.homepage-main-banner-section .container {
  max-width: 1240px;
  margin: auto;
  padding: 8.625rem 3.75rem 7.813rem !important;
}
.homepage-main-banner-section .container h1 span {
  color: #75ffff;
}
.homepage-main-banner-section .container p {
  font-family: "neueMontreal-Thin";
  font-size: 1.875rem;
  font-weight: 100;
  line-height: 2.25rem;
  color: #fafafa;
  margin-top: 2rem;
  text-align: center;
}

.demat--account--section {
  background: #f2f2f2;
  position: relative;
  width: 100%;
  float: left;
  padding: 1.875rem 0px 0px 0px;
}
.demat--account--section .container {
  max-width: 1240px;
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.demat--account--section .container .text-part {
  width: 60%;
}
.demat--account--section .container .text-part h2 {
  margin-bottom: 1.375rem;
}
.demat--account--section .container .text-part .price-text {
  height: 13.063rem;
  margin-bottom: 1.5rem;
  display: flex;
  width: 327.85px;
  flex-direction: row;
}
.demat--account--section .container .text-part .price-text .rupee-symbol {
  font-size: 7.021rem;
  font-weight: 100;
  font-family: "neueMontreal-Regular";
  line-height: 8.424rem;
  color: #001316;
  opacity: 50%;
  align-self: flex-start;
  margin-top: 20px;
}
.demat--account--section .container .text-part .price-text .price {
  font-size: 228.61px;
  line-height: 274.34px;
  font-family: "neuePowerTrial-Regular";
  color: #001316;
  margin-right: 17.85px;
}
.demat--account--section .container .text-part .price-text .per-order {
  max-width: 65px;
  max-height: 54px;
  font-size: 1.404rem;
  line-height: 1.685rem;
  color: #001316;
  opacity: 80%;
  font-family: "neuePowerTrial-Regular";
  margin-top: 157.38px;
}
.demat--account--section .container .text-part p {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.875rem;
  text-align: left;
  font-family: "neueMontreal-Regular";
}
.demat--account--section .container .text-part .second-text {
  line-height: 2.5rem;
  margin-bottom: 1.875rem;
}
.demat--account--section .container .text-part a {
  background: #75ffff;
  font-family: "neuePowerTrial-Medium";
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.625rem;
  text-align: left;
  padding: 0.625rem 1.5rem;
  border-radius: 70px;
  color: #001316;
  display: block;
  width: -moz-max-content;
  width: max-content;
}
.demat--account--section .container .img-part {
  width: 40%;
}
.demat--account--section .container .img-part img {
  margin-bottom: -8px;
}
.demat--account--section .container .img-part .mob-view {
  display: none;
}
.demat--account--section .container .img-part .web-view {
  display: block;
}
.demat--account--section .container .demat--account--img--part img {
  margin-top: -4rem;
  margin-bottom: -5rem;
}
.demat--account--section .container .demat--account--img--part p.sub-heading--text.mb-1 br {
  display: none;
}
.demat--account--section .container .know--more--div .know--more--btn {
  display: block;
}
.demat--account--section .container .demat--section--banner--img {
  margin-top: 3.125rem;
  width: 40%;
}

.who-we-what-we-section {
  position: relative;
  width: 100%;
  float: left;
  margin-top: 0.5rem;
  background: #fffaf7;
}
.who-we-what-we-section .fourth-text--part {
  display: none;
}
.who-we-what-we-section .fifth-text--part {
  display: none;
}

.technology--forefront--section {
  position: relative;
  width: 100%;
  float: left;
  background-color: #001316;
}
.technology--forefront--section .container {
  max-width: 1240px;
  margin: auto;
  padding: 11.5rem 0 8.5rem 0;
}
.technology--forefront--section .container h2 {
  text-align: center;
  margin-bottom: 2.25rem;
  color: #fafafa;
  font-family: "neuePowerTrial-Regular";
  text-transform: capitalize;
}
.technology--forefront--section .container p {
  font-weight: 400;
  color: #fffaf7;
  font-family: "neueMontreal-Regular";
}
.technology--forefront--section .container .technology--card--section {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.technology--forefront--section .container .technology--card--section .technology-card-top {
  background: #cdd2fe;
  border-radius: 20px;
  padding: 3.125rem 0 0 3.125rem;
  display: flex;
  position: relative;
}
.technology--forefront--section .container .technology--card--section .technology-card-top .technology-card-top {
  position: absolute;
  right: 0;
  bottom: 0;
}
.technology--forefront--section .container .technology--card--section .technology-card-top .bg-design {
  position: absolute;
  right: 0;
  bottom: 0;
  width: -moz-fit-content;
  width: fit-content;
}
.technology--forefront--section .container .technology--card--section .technology-card-top .img--part {
  width: 55%;
}
.technology--forefront--section .container .technology--card--section .technology-card-top .img--part img {
  margin-bottom: -0.5rem;
}
.technology--forefront--section .container .technology--card--section .technology-card-top .text--part {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -3rem;
}
.technology--forefront--section .container .technology--card--section .technology-card-top .text--part h3 {
  font-family: "neuePowerTrial-Regular";
  font-size: 3.25rem;
  font-weight: 500;
  line-height: 3.875rem;
  text-align: left;
  margin-bottom: 1rem;
}
.technology--forefront--section .container .technology--card--section .technology-card-top .text--part a {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: #001316;
  font-family: "neuePowerTrial-Regular";
}
.technology--forefront--section .container .technology--card--section .technology-card-top .text--part a img {
  position: absolute;
  width: 1.25rem;
  left: 6rem;
}
.technology--forefront--section .container .technology--card--section .technology-card-top .text--part p {
  text-align: left !important;
  margin-bottom: 1.625rem !important;
  font-family: "neueMontreal-Book";
  font-size: 1.625rem;
  font-weight: 300;
  line-height: 1.875rem;
  color: #001316;
}
.technology--forefront--section .container .technology--card--section .technology-card-bottom {
  gap: 30px;
  padding: 0rem;
  display: flex;
}
.technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common {
  width: 50%;
  position: relative;
}
.technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .bg-pattern-card-two {
  position: absolute;
  right: 0;
  bottom: 0;
  width: -moz-max-content;
  width: max-content;
}
.technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .bg-pattern-card-three {
  position: absolute;
  right: 0;
  top: 0;
  width: -moz-max-content;
  width: max-content;
}
.technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part {
  padding-left: 3rem;
  padding-bottom: 4.875rem;
}
.technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part h3 {
  font-family: "neuePowerTrial-Regular";
  font-size: 3.25rem;
  font-weight: 500;
  line-height: 3.875rem;
  text-align: left;
  margin-bottom: 1rem;
}
.technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part p {
  text-align: left !important;
  margin-bottom: 1.625rem !important;
  font-family: "neueMontreal-Book";
  font-size: 1.625rem;
  font-weight: 300;
  line-height: 1.875rem;
  color: #001316;
}
.technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part a {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: #001316;
  font-family: "neuePowerTrial-Regular";
}
.technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part a img {
  position: absolute;
  width: 1.25rem;
  left: 6rem;
}
.technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(1) {
  border-radius: 30px;
  background: #ffab6e;
}
.technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(2) {
  border-radius: 30px;
  background: #339581;
}
.technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(2) .text--part {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(2) .img-part {
  padding: 50px;
}

@media (max-width: 900px) {
  .homepage-main-banner-section .container {
    padding: 5.25rem 1rem 7.813rem !important;
  }
  .homepage-main-banner-section .container h1 {
    font-size: 2.625rem;
    font-weight: 400;
    line-height: 3.375rem;
    margin-bottom: 0.75rem;
    font-family: "neuePowerTrial-Medium";
    text-align: center;
  }
  .homepage-main-banner-section .container p {
    font-size: 1.25rem;
    font-weight: 100;
    line-height: 2rem;
    padding: 0;
  }
  .homepage-main-banner-section .container p br {
    display: none;
  }
  .demat--account--section {
    padding: 1rem 1rem 0rem 1rem;
  }
  .demat--account--section .container {
    flex-direction: column;
  }
  .demat--account--section .container .text-part {
    width: 100%;
  }
  .demat--account--section .container .text-part h2 {
    font-size: 42px;
    font-weight: 600;
    line-height: 57.6px;
    text-align: left;
    margin-bottom: 2rem;
  }
  .demat--account--section .container .text-part img {
    max-width: 18rem;
  }
  .demat--account--section .container .text-part p {
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: left;
  }
  .demat--account--section .container .text-part .second-text {
    font-size: 17px;
    font-weight: 400;
    line-height: 20.4px;
    text-align: left;
  }
  .demat--account--section .container .text-part a {
    width: 100%;
    text-align: center;
  }
  .demat--account--section .container .img-part {
    width: 100%;
  }
  .demat--account--section .container .img-part img {
    margin-bottom: -8px;
  }
  .demat--account--section .container .img-part .mob-view {
    display: block;
  }
  .demat--account--section .container .img-part .web-view {
    display: none;
  }
  .demat--account--section .container .demat--account--img--part img {
    margin-top: -4rem;
    margin-bottom: -5rem;
  }
  .demat--account--section .container .demat--account--img--part p.sub-heading--text.mb-1 br {
    display: none;
  }
  .demat--account--section .container .know--more--div .know--more--btn {
    display: block;
  }
  .demat--account--section .container .demat--section--banner--img {
    margin-top: 3.125rem;
    width: 40%;
  }
  .technology--forefront--section .container {
    padding: 3rem 1rem 10rem 1rem;
  }
  .technology--forefront--section .container h2 {
    font-size: 2.625rem;
    font-weight: 500;
    line-height: 68px;
    text-align: left;
    margin-bottom: 2rem;
  }
  .technology--forefront--section .container h2 span {
    font-weight: 500;
    line-height: 68px;
    text-align: left;
  }
  .technology--forefront--section .container p {
    font-size: 18px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
  }
  .technology--forefront--section .container p br {
    display: none;
  }
  .technology--forefront--section .container .technology--card--section {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-top {
    padding: 3rem 1rem 0rem 1rem;
    flex-direction: column-reverse;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-top .bg-design {
    display: none;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-top .img--part {
    width: 100%;
    margin-top: 3.75rem;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-top .img--part img {
    margin-bottom: -0.5rem;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-top .text--part {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-top .text--part h3 {
    font-size: 38px;
    font-weight: 500;
    line-height: 45.6px;
    text-align: left;
    margin-bottom: 0.75rem;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-top .text--part p {
    font-size: 20px;
    line-height: 23.44px;
    text-align: left;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-top .text--part a {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-top .text--part a img {
    position: absolute;
    width: 1.25rem;
    left: 7.5rem;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-bottom {
    flex-direction: column;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common {
    width: 100%;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .bg-pattern-card-two {
    display: none;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .bg-pattern-card-three {
    display: none;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part {
    padding: 1rem 1rem 5.25rem 1rem;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part h3 {
    font-size: 38px;
    font-weight: 500;
    line-height: 45.6px;
    text-align: left;
    margin-bottom: 0.75rem;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part p {
    font-size: 20px;
    line-height: 23.44px;
    text-align: left;
    margin-bottom: 1.5rem;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part a {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common .text--part a img {
    position: absolute;
    width: 1.25rem;
    left: 7.5rem;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(1) {
    border-radius: 30px;
    background-repeat: no-repeat;
    background-position: right bottom;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(2) {
    border-radius: 30px;
    background-repeat: no-repeat;
    background-position: right top;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(2) .text--part {
    padding-top: 5.25rem;
    padding-bottom: 1rem;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(2) .text--part h3 {
    margin-bottom: 1rem;
  }
  .technology--forefront--section .container .technology--card--section .technology-card-bottom .technology-card-bottom-common:nth-child(2) .img-part {
    padding: 1rem;
  }
}
/*IPO HOMEPAGE CSS START*/
.homepage-main-banner-section1 {
  position: relative;
  width: 100%;
  float: left;
  background: #001316;
  margin-bottom: 5rem;
}
.homepage-main-banner-section1 .container {
  max-width: 1240px;
  margin: auto;
  padding: 0;
}
.homepage-main-banner-section1 .container h1 span {
  color: #75ffff;
}
.homepage-main-banner-section1 .container p {
  font-family: "neueMontreal-Thin";
  font-size: 1.875rem;
  font-weight: 100;
  line-height: 2.25rem;
  color: #fafafa;
  margin-top: 2rem;
  text-align: center;
}

.main-section-ipo {
  background-color: #fff;
}

.ipobox-section {
  margin: auto;
  max-width: 1240px;
  text-align: center;
}
.ipobox-section h2 {
  color: #001317;
  line-height: 60px;
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 20px;
  font-family: neuePowerTrial-Medium;
}
.ipobox-section h2 span {
  color: #001316;
  line-height: 60px;
  font-size: 50px;
  font-weight: 300;
  margin-bottom: 20px;
  font-family: Canela Trial;
  font-style: italic;
}
.ipobox-section p {
  color: #001317;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 40px;
}
.ipobox-section p br {
  display: none;
}

.company-bread-crum-top-section {
  height: 48px;
  margin-top: 80px;
  position: relative;
  background: #f5f5f5;
}
.company-bread-crum-top-section .container {
  max-width: 1240px;
  margin: auto;
  padding: 0;
}
.company-bread-crum-top-section .container .bread-crumb-company-details {
  display: flex;
  position: relative;
  height: 48px;
  align-items: center;
}
.company-bread-crum-top-section .container .bread-crumb-company-details .bread-crumb-company-details-item {
  color: #001316;
  font-family: "neueMontreal-Regular";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: left;
  margin: 1.5rem 0;
}
@media screen and (max-width: 500px) {
  .company-bread-crum-top-section .container .bread-crumb-company-details .bread-crumb-company-details-item {
    font-size: 14px;
  }
  .company-bread-crum-top-section .container .bread-crumb-company-details .bread-crumb-company-details-item:last-child {
    word-break: break-word;
    overflow-wrap: break-word;
  }
}
.company-bread-crum-top-section .container .bread-crumb-company-details .bread-crumb-company-details-item:nth-child(1)::before {
  content: "";
  float: left;
  padding: 0rem;
}
.company-bread-crum-top-section .container .bread-crumb-company-details .bread-crumb-company-details-item::before {
  content: "/";
  float: left;
  padding: 0rem 0.45rem;
}
.company-bread-crum-top-section .container .bread-crumb-company-details .bread-crumb-company-details-item a {
  color: #001316;
  font-family: "neueMontreal-Regular";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: left;
}
@media screen and (max-width: 500px) {
  .company-bread-crum-top-section .container .bread-crumb-company-details .bread-crumb-company-details-item a {
    font-size: 14px;
  }
}
.company-bread-crum-top-section .container .bread-crumb-company-details .bread-crumb-company-details-item:hover {
  text-decoration: underline;
}

@media (max-width: 900px) {
  .company-bread-crum-top-section {
    margin-top: 78px;
    padding: 0 16px;
  }
}
.tab-ipo-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}
.tab-ipo-section a {
  color: rgba(0, 19, 22, 0.5019607843);
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
}

.firstsecond {
  margin: auto;
  max-width: 1240px;
  background-color: #001317;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 80px 0px;
}

.firstsect-color {
  background-color: #001317;
}

.home-sec {
  background-color: #001317;
  padding: 50px 0px;
}

.stepsclass {
  padding: 0 16px;
  width: 50%;
}
.stepsclass img {
  margin-top: 20px;
}
.stepsclass h2 {
  color: #fff;
  font-family: neuePowerTrial-Regular;
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  text-align: left;
  min-width: -moz-max-content;
  min-width: max-content;
}
.stepsclass ul {
  margin-top: 40px;
}
.stepsclass ul li {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  padding-bottom: 25px;
  list-style: none;
}

.video-width {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.sectionsecond {
  background-color: #fcfcfc;
  text-align: center;
  margin: auto;
  max-width: 1240px;
  padding: 70px 20px;
}
.sectionsecond .important-content {
  display: flex;
  gap: 15px;
}
.sectionsecond h2 {
  color: #001316;
  font-family: neuePowerTrial-Medium;
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  padding-bottom: 40px;
}
.sectionsecond p {
  color: #001316;
  font-size: 20px;
  line-height: 29px;
  margin-bottom: 20px;
  font-weight: 400;
  text-align: start;
  font-family: "PP Neue Montreal", sans-serif;
}
@media only screen and (max-width: 580px) {
  .sectionsecond p {
    font-size: 18px;
  }
}
.sectionsecond p br {
  display: none;
}

.thirdsecond {
  margin: auto;
  max-width: 1240px;
  background-color: #001316;
  text-align: center;
  padding: 70px 20px;
  margin-bottom: 4px;
}
.thirdsecond .avoid-content {
  display: flex;
  gap: 10px;
}
.thirdsecond h2 {
  color: #fff;
  font-family: neuePowerTrial-Medium;
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  padding-bottom: 40px;
}
.thirdsecond p {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  padding: 0px 30px;
  text-align: start;
  line-height: 30px;
  margin-bottom: 20px;
}

.radioipo-sec {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.radioipo-sec .ipo__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #001317;
}
.radioipo-sec .flexradio {
  gap: 12px;
  flex-wrap: wrap;
}

.flexradio {
  display: flex;
  gap: 40px;
}
.flexradio .check__wrapper {
  color: #001316;
  font-size: 16px;
  font-weight: 500;
  font-family: "PP Neue Montreal", sans-serif;
  line-height: 19.2px;
  letter-spacing: 0.03em;
  text-align: left;
  display: flex;
  align-items: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
}
.flexradio .check__wrapper input {
  width: 20px;
  height: 20px;
}
.flexradio .check__wrapper input[type=radio]:checked {
  accent-color: #232323;
}

.ipo-detail-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.firstipo-dt {
  background-color: #fff;
  box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.0901960784);
  position: relative;
  border-radius: 6px;
  cursor: pointer;
}

.first-div {
  width: 90%;
  justify-content: space-between;
  display: flex;
}

.left-div {
  margin-bottom: 15px;
  width: 70%;
  display: flex;
  gap: 8px;
}

.img-h2 {
  margin-top: 10px;
}

.btn-wrap {
  display: flex;
  width: 100%;
  gap: 14px;
}

.main-btn {
  color: #ababab;
  border-radius: 6px;
  border: 1px solid #ababab;
}

.open-btn {
  background-color: #9e6240;
  color: white;
  border: none;
  width: 70px;
  border-radius: 6px;
}

.second-div {
  width: 90%;
  justify-content: space-between;
  display: flex;
  margin-bottom: 12px;
  margin-top: 20px;
}

.table-header {
  color: #ababab;
  font-family: PP Neue Montreal;
}

.separator {
  border-top: 2px dashed #ababab;
  margin: 20px 0;
  height: 0;
  width: 90%;
}

.ipo-card-section {
  text-align: left;
  margin: 20px 0px 0px 15px;
}
.ipo-card-section h6 {
  color: var(--clr);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90px;
}
.ipo-card-section .price-right-percentage {
  width: auto;
}
.ipo-card-section .price-right-percentage.positive-green::before {
  content: "▲";
  color: var(--clr);
  margin-right: 2px;
}
.ipo-card-section .price-right-percentage.negative-red::before {
  content: "▼";
  color: var(--clr);
  margin-right: 2px;
}
.ipo-card-section .company-name-details {
  display: flex;
  align-items: center;
}
.ipo-card-section .company-name-details p {
  color: #ababab;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
}
.ipo-card-section .company-name-details span {
  color: #ababab;
  font-size: 9px;
  font-weight: 500;
  border: 1px solid #ababab;
  padding: 4px;
  border-radius: 6px;
  margin-left: 5px;
}
.ipo-card-section img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.ipo-card-section .company__logo {
  background: rgba(171, 171, 171, 0.2);
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.ipo-card-main {
  display: flex;
  justify-content: space-between;
}

.closestm {
  position: absolute;
  right: 0;
}
.closestm p {
  color: #c43a4b;
  font-size: 12px;
  font-weight: 500;
}
.closestm p span {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  background-color: #037171;
  padding: 4px 6px;
  position: relative;
  top: 0px;
  border-radius: 0px 6px 0px 6px;
}

.ipo-card-section1 {
  display: flex;
  padding-bottom: 13px;
}

.listed-details-top .ipo-card-section:last-child {
  text-align: end;
  width: 100%;
  margin: 20px;
}

.sectors-pg {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px 15px 15px;
}
.sectors-pg.sectors-sec-pg {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.sectors-pg.sectors-sec-pg .in-sectors:nth-child(3) p {
  text-align: start;
}
.sectors-pg .in-sectors {
  text-align: left;
}
.sectors-pg .in-sectors p {
  color: #ababab;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 3px;
}
.sectors-pg .in-sectors h6 {
  color: #001317;
  font-size: 14px;
  font-weight: 500;
}
.sectors-pg .in-sectors h6 span {
  color: var(--clr);
}
.sectors-pg .in-sectors h6 span .price-sec-value {
  margin-left: 6px;
  font-weight: 400;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: var(--clr);
}
.sectors-pg .in-sectors button {
  padding: 6px 35px;
  background-color: #001317;
  font-size: 14px;
  font-weight: 500;
  color: #75ffff;
  border-radius: 100px;
  font-family: neuePowerTrial-Medium;
  position: relative;
  cursor: pointer;
}
.sectors-pg .in-sectors:nth-child(3) p {
  text-align: right;
}
.sectors-pg .in-sectors:nth-child(3) h5 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: right;
}
.sectors-pg .listed-cta button {
  padding: 8px 18px;
  background-color: #001317;
  font-size: 14px;
  font-weight: 500;
  color: #75ffff;
  border-radius: 100px;
  font-family: neuePowerTrial-Medium;
  position: relative;
  cursor: pointer;
}

.invest-section .cointainer {
  margin: auto;
  max-width: 1240px;
  display: flex;
  padding: 60px 0px;
}

.divided-sec {
  width: 50%;
}
.divided-sec h2 {
  font-size: 50px;
  font-weight: 500;
  color: #001317;
  line-height: 50px;
  padding-top: 30px;
}
.divided-sec p {
  font-size: 20px;
  font-weight: 400;
  color: #001317;
  padding: 40px 0px 70px 0px;
}
.divided-sec button {
  padding: 10px 35px;
  background-color: #75ffff;
  font-size: 20px;
  font-weight: 500;
  color: #001317;
  border-radius: 36px;
  width: 70%;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.ipobox-section1 {
  max-width: 1240px;
  background-color: #fff;
  text-align: left;
  margin: 0px 15px;
}
.ipobox-section1 h1 {
  color: #001316;
  line-height: 60px;
  font-weight: 500;
  margin-bottom: 20px;
  font-family: neuePowerTrial-Medium;
  font-size: 40px;
}
.ipobox-section1 p {
  color: #001316;
  font-weight: 400;
  margin-bottom: 35px;
  font-size: 18px;
}

.tab-ipo-section1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  margin: 0 auto;
  width: 100%;
}
.tab-ipo-section1 a {
  color: rgba(0, 19, 22, 0.5019607843);
  font-size: 16px;
  font-weight: 400;
  padding: 10px 20px;
}

.ipo-detail-section1 {
  display: flex;
}
.ipo-detail-section1 .firstipo-dt:nth-child(1) {
  margin-right: 20px;
}

.remain-days .close-days {
  font-size: 12px;
  font-weight: 500;
  background-color: #ffd1d7;
  padding: 6px 12px;
  position: relative;
  right: -16px;
  bottom: -27px;
  border-radius: 6px 0px 6px 0px;
}
.remain-days .close-days.red {
  color: #c43a4b;
  background-color: #ffd1d7;
}
.remain-days .close-days.yellow {
  color: #FF9400;
  background-color: #FFFAE1;
}

.nb-stock {
  display: flex;
  cursor: pointer;
}
.nb-stock button {
  cursor: pointer !important;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  height: 30px;
}
.nb-stock .active {
  color: #75ffff;
  font-size: 14px;
  font-weight: 500;
  background-color: #001317;
  height: 30px;
}

.viewall-ipo {
  width: -moz-max-content;
  width: max-content;
  margin-inline-start: auto;
}
.viewall-ipo .links-btn {
  color: #001316;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 60px;
  font-family: neueMontreal-Regular;
  display: flex;
  align-items: center;
  gap: 8px;
}

.ipo-active-tab {
  color: #001316 !important;
  border-bottom: 2px solid #001316;
  font-weight: 600 !important;
}

.width-arw {
  width: 6px;
  margin-left: 5px;
}

.webpwidth {
  display: flex;
  justify-content: end;
}
.webpwidth img {
  width: 100%;
}

.ipo-stocksec-main {
  display: flex;
  margin-top: 60px;
  justify-content: space-between;
  gap: 70px;
  padding-top: 50px;
}
.ipo-stocksec-main .ipo-stocksec-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 124px;
}
.ipo-stocksec-main .ipo-stocksec-content h1 {
  font-size: 50px;
  font-weight: 500;
  color: #fff;
  font-family: neuePowerTrial-Medium;
}
.ipo-stocksec-main .ipo-stocksec-content h1 br {
  display: none;
}
.ipo-stocksec-main .ipo-stocksec-content p {
  font-size: 24px;
  font-weight: 500;
  color: #FFFAF7;
  font-family: "neueMontreal-Thin";
  margin-top: 2rem;
  text-align: left;
}
.ipo-stocksec-main .form--section--get--started {
  margin: 83px 0px 0px 0px;
}

.start-your-invest-wrap {
  background-color: #fff;
  padding: 60px 0px 0px 0px;
}
.start-your-invest-wrap .container {
  margin: auto;
  max-width: 1240px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 70vh;
}
.start-your-invest-wrap .container .invest-img-section {
  position: relative;
  height: 100%;
}
.start-your-invest-wrap .container .invest-img-section .invest-img-web {
  position: absolute;
  bottom: 0;
  right: 20%;
  width: 60%;
}
.start-your-invest-wrap .container .invest-img-section .invest-img-mob {
  display: none;
}

.invest-content-section {
  width: 50%;
  padding-top: 60px;
}
.invest-content-section h2 {
  color: #001316;
  font-family: neuePowerTrial-Medium;
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  padding-bottom: 30px;
}
.invest-content-section p {
  color: #001316;
  font-size: 20px;
  font-weight: 400;
  width: 570px;
  width: 100%;
}
.invest-content-section a {
  font-size: 20px;
  font-weight: 500;
  color: #001317;
  padding: 10px 60px;
  border-radius: 30px;
  background-color: #75ffff;
  border: 0;
  margin-top: 20px;
  width: 70%;
  font-family: neuePowerTrial-Medium;
}
.invest-content-section .p1 {
  display: block;
}
@media only screen and (max-width: 580px) {
  .invest-content-section .p1 {
    display: none;
  }
}
.invest-content-section .p2 {
  display: none;
}
@media only screen and (max-width: 580px) {
  .invest-content-section .p2 {
    display: block;
  }
}

.invest-img-section {
  width: 50%;
  padding-top: 60px;
  display: flex;
  justify-content: center;
}

.testimonial-section {
  margin: 0px auto;
  text-align: center;
}
.testimonial-section h2 {
  font-family: neuePowerTrial-Medium;
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  padding-bottom: 20px;
}

.description {
  margin-bottom: 40px;
  color: #666;
  font-size: 16px;
  font-weight: 400;
  font-family: "PPNeueMontreal-Light";
  padding: 0px 510px;
  padding: 0 20px;
}

.testimonials {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-bottom: 20px;
}

.testimonial {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 30%;
  text-align: left;
}
.testimonial p {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.author {
  display: flex;
  align-items: center;
}

.avatar {
  width: 50px;
  height: 50px;
  background-color: #333;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  margin-right: 10px;
}

.details {
  text-align: left;
}
.details h4 {
  font-size: 18px;
  margin-bottom: 5px;
}
.details span {
  font-size: 14px;
  color: #888;
}

.rating {
  color: #ff9900;
}

.dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
}
.dot.active {
  background-color: #333;
}

.sectionsecond1 {
  background-color: #fcfcfc;
  padding: 60px 0px 34px 0px;
  text-align: center;
}

.ipo-list-wrapper {
  padding-block: 40px;
}

.common-card-widget {
  height: auto;
  background-color: #fff;
}

.ipo-filter-wrapper {
  display: flex;
  justify-content: space-between;
  padding-block: 40px;
  padding: 25px 10px;
  flex-direction: row-reverse;
  row-gap: 40px;
}
.ipo-filter-wrapper .radioipo-sec {
  margin-bottom: 0px !important;
}

.home-page-ipo-list .ipo-filter-wrapper {
  padding-block: 0px;
  padding-bottom: 24px !important;
}
.home-page-ipo-list .ipo-filter-wrapper h5 {
  font-size: 16px;
}

.ipo-filter-wrapper-list {
  justify-content: start !important;
}
.ipo-filter-wrapper-list .nb-stock {
  padding-left: 30px !important;
}
.ipo-filter-wrapper-list .filter-controls .nb-stock {
  padding-left: 20px !important;
}

.filter-controls {
  display: flex;
}
.filter-controls .nb-stock {
  padding-right: 20px;
}

.ipo-filter-wrapper-home .filter-controls {
  flex-direction: row-reverse;
}

.eHSVWh.active {
  font-weight: 600 !important;
  color: #001316;
}

.check__wrapper span {
  font-family: neueMontreal-Regular;
  font-size: 12px;
  color: #001316;
  font-weight: 500;
}

.faq--container--section .container {
  display: block;
}
.faq--container--section .container .left-part {
  width: 100%;
}
.faq--container--section .container .right-part {
  width: 100%;
}
.faq--container--section .container .right-part .faq-container {
  margin: 0;
  width: 100%;
}

.right-part {
  width: 100%;
  margin-top: 25px;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 30px 0px;
}

.banner-main-heading1 {
  display: block;
  font-size: 50px;
  line-height: 60px;
  text-align: left;
}

.banner-main-heading-mb1 {
  display: none;
  font-family: "neuePowerTrial-Medium";
  font-size: 50px;
  font-weight: 500;
  line-height: 50px;
  text-align: left;
  color: #fffaf7;
}

.sec-top-wrap .Open {
  background-color: #6DC034;
  color: #fff;
  border: 0;
}
.sec-top-wrap .Upcoming {
  background-color: #F4D933;
  color: #fff;
  border: 0;
}
.sec-top-wrap .Closed {
  background-color: #9E6240;
  color: #fff;
  border: 0;
}

.in-sectors button img {
  display: none;
}

@media only screen and (min-width: 900px) {
  .firstsecond {
    flex-direction: row;
    gap: 40px;
  }
}
@media only screen and (min-width: 1200px) {
  .firstsecond {
    flex-direction: row;
  }
}
.dot-background {
  width: 100%;
  height: 482px;
}

#slider1.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #001316;
}

#slider1.owl-theme .owl-dots .owl-dot {
  margin: 42px;
}
#slider1.owl-theme .owl-dots .owl-dot span {
  background: rgba(0, 19, 22, 0.3019607843);
  width: 11px;
  height: 11px;
}

.testimonial__card {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 18px;
  margin: 10px 0;
  flex-direction: column;
}
.testimonial__card .testimonial__content {
  background: #f3f5f7;
  padding: 24px;
  height: 136px;
  border-radius: 10px;
  position: relative;
}
.testimonial__card .testimonial__content::before {
  background: #f3f5f7;
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  border-radius: 2px;
  bottom: -10px;
  left: 25px;
  rotate: 45deg;
}
.testimonial__card .testimonial__content p {
  font-family: "neueMontreal-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  height: 100%;
}
.testimonial__card .testimonial__content .playstore_Applestore img {
  width: 20px;
  height: 20px;
  -o-object-fit: contain;
     object-fit: contain;
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.testimonial__card .testimonial__profile {
  height: 72px;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.testimonial__profile .profile {
  width: 72px;
  height: 72px;
  background: #001316;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonial__profile .profile h3 {
  font-family: "neueMontreal-Regular";
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: center;
  color: #d9d9d9;
}
.testimonial__profile .profile__content {
  display: flex;
  flex-direction: column;
}
.testimonial__profile .profile__content h5 {
  font-family: "neueMontreal-Regular";
  font-size: 20px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
  color: #001316;
}
.testimonial__profile .profile__content .profile__des {
  display: flex;
  gap: 6px;
  align-items: baseline;
}
.testimonial__profile .profile__content .profile__des .date {
  font-family: "neueMontreal-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
}

.profile__content .profile__des .rating {
  font-size: 18px;
  color: #ff9900;
}

.ipo-title-wrapper {
  order: 1;
  text-align: left;
}

.invest-content-section,
.invest-img-section {
  padding: 60px 0 0 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 576px) {
  .invest-content-section,
  .invest-img-section {
    padding: 32px 16px 0px 16px;
  }
}

@media only screen and (max-width: 991px) {
  .ipo-stocksec-main .ipo-stocksec-content {
    width: 80%;
  }
  .homepage-main-banner-section .container {
    padding: 90px 20px 0px 20px;
  }
  .invest-content-section,
  .invest-img-section {
    padding: 20px 20px 0px 20px;
  }
  .invest-content-section p {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .stepsclass {
    width: 100%;
    padding: 0;
  }
  .sectionsecond .important-content {
    display: block;
  }
  .sectionsecond h2 {
    font-size: 40px;
  }
  .thirdsecond .avoid-content {
    display: block;
  }
  .thirdsecond p {
    padding: 0px 10px;
    font-size: 18px;
  }
  .webpwidth {
    justify-content: center;
  }
  .webpwidth img {
    width: 95%;
  }
  .ipo-stocksec-main {
    display: block;
    margin-top: 120px;
    padding: 0px 16px;
  }
  .ipo-stocksec-main .ipo-stocksec-content {
    width: 100%;
    align-items: center;
  }
  .start-your-invest-wrap .container {
    display: block;
    height: auto;
  }
  .start-your-invest-wrap .container .invest-img-section .invest-img-web {
    display: none;
  }
  .start-your-invest-wrap .container .invest-img-section .invest-img-mob {
    display: block;
  }
  .invest-content-section a {
    padding: 10px 30px;
    width: 100%;
    font-size: 14px;
  }
  .ipo-filter-wrapper {
    flex-direction: column;
    padding: 24px 0px;
  }
  .homepage-main-banner-section .container {
    padding: 40px 0px 0px 0px;
  }
  .sectors-pg button {
    right: -14px !important;
    bottom: -16px !important;
  }
  .sectors-pg button::after {
    content: "";
    height: 8px;
    width: 8px;
    border-color: #75ffff;
    border-style: solid;
    border-width: 2px 2px 0px 0px;
    position: absolute;
    right: 18px;
    top: 11px;
    transform: rotate(45deg);
  }
  .sectors-pg .listed-cta button {
    font-size: 10px;
  }
  .sectors-pg .listed-cta button::after {
    content: "";
    height: 7px;
    width: 7px;
    border-color: #75ffff;
    border-style: solid;
    border-width: 2px 2px 0px 0px;
    position: absolute;
    right: 8px;
    top: 11px;
    transform: rotate(45deg);
  }
}
@media only screen and (max-width: 567px) {
  .banner-main-heading1 {
    display: none;
  }
  .banner-main-heading-mb1 {
    display: block;
  }
  .tab-ipo-section1 a {
    font-size: 14px;
    padding: 10px 6px;
  }
  .tab-ipo-section1 a span {
    display: none;
  }
  .ipo-stocksec-main .form--section--get--started {
    width: 100%;
    margin: 50px 0 0;
  }
  .ipo-stocksec-main .ipo-stocksec-content h1 {
    text-align: center;
    font-size: 42px;
    line-height: 50px;
  }
  .ipo-stocksec-main .ipo-stocksec-content h1 br {
    display: block;
  }
  .form--section--get--started .custom__search .referal-code-part .referal-code-design-part {
    align-items: center;
  }
  .form--section--get--started .custom__search .referal-code-part .warning-msg {
    right: 80px;
  }
  .homepage-main-banner-section1 {
    margin-bottom: 32px;
  }
  .ipobox-section {
    padding: 0px 16px;
  }
  .firstsecond {
    padding: 32px 16px 100px 16px;
    gap: 10px;
  }
  .sectionsecond {
    padding: 32px 16px;
  }
  .sectionsecond h2 {
    line-height: 48px;
    padding: 0px 30px 24px 30px;
  }
  .thirdsecond {
    padding: 32px 16px;
  }
  .thirdsecond h2 {
    font-size: 40px;
    line-height: 48px;
    padding-bottom: 24px;
  }
  .testimonial-section h2 {
    font-size: 40px;
    line-height: 48px;
    padding-bottom: 24px;
  }
  .sectionsecond1 {
    padding: 28px 0 34px;
  }
  .faq--container--section {
    padding: 32px 0px;
  }
  .faq--container--section .container .left-part h2 {
    line-height: 38.8px !important;
    text-align: center !important;
    font-size: 32px !important;
  }
  .faq--container--section .container .right-part .faq-container details summary .faq-title {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .stepsclass h2 {
    font-size: 40px;
    line-height: 48px;
    min-width: auto;
  }
  .stepsclass h2 br {
    display: none;
  }
  .stepsclass h2 img {
    margin-top: 0;
  }
  nav .container .mobile-menu .search-container {
    width: auto;
  }
  .ipo-stocksec-main .ipo-stocksec-content p {
    font-size: 20px;
  }
  .ipobox-section h2,
  .ipobox-section h2 span {
    font-size: 48px;
  }
  .ipobox-section p {
    font-size: 16px;
    margin-bottom: 0;
  }
  .ipo-card-section .company-name-details p {
    font-size: 12px;
  }
  .viewall-ipo .links-btn {
    margin-bottom: 0px;
  }
  .in-sectors p {
    font-size: 12px;
  }
  .in-sectors button {
    border-radius: 6px 0px 6px 0px;
    padding: 10px 26px 12px 26px;
    position: absolute;
    right: 0;
    bottom: 0px;
    display: flex;
    align-items: center;
  }
  .in-sectors button img {
    display: block;
    width: 7px;
    margin-left: 8px;
  }
  .invest-content-section h2 {
    font-size: 48px;
    line-height: 57px;
  }
  .invest-content-section a {
    text-align: center;
  }
  .ipobox-section h2 {
    font-size: 40px;
  }
  .tab-ipo-section1 {
    padding-top: 20px;
  }
  .ipo-list-wrapper {
    padding-top: 20px;
  }
  .flexradio .check__wrapper {
    -moz-column-gap: 5px;
         column-gap: 5px;
  }
  .radioipo-sec {
    margin: 0;
  }
  .radioipo-sec .flexradio {
    flex-wrap: nowrap;
    gap: 5px;
    display: flex;
    justify-content: space-between;
  }
  .filter-controls {
    justify-content: center;
  }
  .ipobox-section1 {
    margin: 0 0px;
  }
}/*# sourceMappingURL=homepage.css.map */
